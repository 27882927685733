<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article>
        <img
          :src="
            formData.imagen && typeof formData.imagen == 'object'
              ? formData.imagen.imagen
              : require('./../assets/noimage.png')
          "
          style="
            position: absolute;
            width: 52px;
            height: 52px;
            top: 4px;
            left: 6px;
          "
        />
        <field
          name="codigo"
          widget="input"
          searchable
          label="Código"
          help="Cód. del Proveedor"
          placeholder="Cód."
          width="67px"
          style="top: 9px; left: 68px"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="nombre_comercial"
          max="25"
          widget="input"
          label="Nombre Comercial"
          help="Nombre Comercial del Proveedor"
          required
          placeholder="Nombre Comercial"
          width="250px"
          style="top: 9px; left: 142px"
          searchable
          inputStyle="font-weight:bold;"
          @input="onInputNombreComercial"
          @focus="onFocusNombreComercial"
        />
        <field
          name="activo"
          widget="toggle"
          ontext="Activo"
          offtext="Inactivo"
          :labels="{ checked: 'Activo', unchecked: 'Inactivo' }"
          :width="75"
          inline-label
          style="top: 19px; left: 567px"
          searchable
        />
        <field
          name="nif_cif"
          widget="input"
          label="NIF/CIF"
          help="NIF/CIF del Proveedor"
          max="11"
          required
          placeholder="NIF/CIF"
          searchable
          width="100px"
          style="top: 9px; left: 398px"
          @change="onChangeNifCif"
          :class="{
            wrong:
              nif_cif_duplicado ||
              (formData.nif_cif &&
                !$utils.validation.checkVATNumber(formData.nif_cif)),
          }"
        />
        <r-tabs
          ref="main-tabs"
          style="top: 55px; height: 278px"
          :buttons="[
            'Fiscal',
            'Comercial',
            'Dir.',
            'Contactos',
            'Fact.',
            'Banco',
            'Web',
            'Notas',
            'Img',
            'Saldos',
          ]"
        >
          <div class="tab">
            <field
              name="nombre_fiscal"
              widget="input"
              label="Nombre"
              help="Razon Social del Proveedor"
              inline-label
              placeholder="Nombre Fiscal"
              searchable
              width="280px"
              style="top: 30px; right: 305px"
              required
              @blur="onBlurNombreFiscal"
            />
            <field
              name="direccion_fiscal"
              widget="input"
              label="Dirección"
              help="Dirección Fiscal del Proveedor"
              inline-label
              placeholder="Dirección Fiscal"
              searchable
              width="340px"
              style="top: 65px; right: 245px"
              @change="onChangeCopy('direccion_fiscal', 'direccion_comercial')"
            />
            <field
              name="direccion_fiscal1"
              widget="input"
              label
              inline-label
              searchable
              width="340px"
              style="top: 95px; right: 245px"
              @change="
                onChangeCopy('direccion_fiscal1', 'direccion_comercial1')
              "
            />
            <field
              name="cp_fiscal"
              max="8"
              inputalign="center"
              widget="input"
              label="C.P."
              help="Código Postal del Proveedor"
              inline-label
              placeholder="CP"
              searchable
              width="64px"
              style="top: 125px; right: 522px"
              @change="onChangeCopy('cp_fiscal', 'cp_comercial')"
            />
            <field
              name="poblacion_fiscal"
              widget="input"
              label
              inline-label
              placeholder="Población Fiscal"
              searchable
              width="280px"
              style="top: 125px; right: 242px"
              @change="onChangeCopy('poblacion_fiscal', 'poblacion_comercial')"
            />
            <field
              name="provincia_fiscal_id"
              dbAdapter="provincia"
              widget="m2o"
              label="Provincia"
              help="Provincia del Proveedor"
              inline-label
              placeholder="Provincia Fiscal"
              searchable
              width="260px"
              style="top: 160px; right: 325px"
              :fields="[
                'nombre',
                {
                  name: 'pais_id',
                  fields: [
                    'nombre',
                    { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                    'nacionalidad',
                    { name: 'moneda_id', fields: ['nombre'] },
                  ],
                },
              ]"
              @select="
                onChangeCopy('provincia_fiscal_id', 'provincia_comercial_id')
              "
            />
            <field
              name="pais_fiscal_id"
              dbAdapter="pais"
              widget="m2o"
              label="Pais"
              placeholder="Pais Fiscal"
              help="Pais del Proveedor"
              inline-label
              searchable
              width="260px"
              style="top: 190px; right: 325px"
              @select="onChangeCopy('pais_fiscal_id', 'pais_comercial_id')"
              :fields="[
                { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                'nacionalidad',
                { name: 'moneda_id', fields: ['nombre'] },
              ]"
            />

            <field
              name="telefono_fiscal"
              widget="input"
              label="Teléfono"
              inline-label
              placeholder="Teléfono"
              help="Teléfono del Proveedor"
              searchable
              width="120px"
              style="top: 30px; right: 70px"
              @change="onChangeCopy('telefono_fiscal', 'telefono_comercial')"
            />
            <field
              name="movil_fiscal"
              widget="input"
              label="Movil"
              inline-label
              placeholder="Movil"
              help="Teléfono Movil del Proveedor"
              searchable
              width="120px"
              style="top: 65px; right: 70px"
              @change="onChangeCopy('movil_fiscal', 'movil_comercial')"
            />
            <field
              name="email_fiscal"
              widget="input"
              label="E-Mail"
              inline-label
              placeholder="e-mail"
              help="E-Mail del Proveedor"
              searchable
              width="180px"
              :class="{
                wrong:
                  formData.email_fiscal &&
                  !$utils.validation.checkEmail(formData.email_fiscal),
              }"
              style="top: 95px; right: 10px"
              @change="onChangeCopy('email_fiscal', 'email_comercial')"
            />
            <field
              name="web_fiscal"
              widget="input"
              label="Web"
              inline-label
              placeholder="Web"
              help="Web del Proveedor"
              searchable
              width="180px"
              style="top: 125px; right: 10px"
              @change="onChangeCopy('web_fiscal', 'web_comercial')"
            />
            <field
              name="adjuntos"
              :max="6"
              label="Adjuntos"
              help="Archivos Adjuntos del Proveedor"
              widget="files"
              style="top: 170px; right: 10px"
            />
          </div>
          <div class="tab">
            <field
              name="responsable"
              max="35"
              widget="input"
              label="Responsable"
              placeholder="Responsable"
              help="Responsable de la empresa"
              inline-label
              searchable
              width="280px"
              style="top: 30px; right: 300px"
              required
            />
            <field
              name="direccion_comercial"
              max="30"
              widget="input"
              label="Dirección"
              help="Dirección Comercial del Proveedor"
              inline-label
              placeholder="Dirección Comercial"
              searchable
              width="340px"
              style="top: 65px; right: 240px"
            />
            <field
              name="direccion_comercial1"
              max="30"
              widget="input"
              label
              inline-label
              searchable
              width="340px"
              style="top: 95px; right: 240px"
            />
            <field
              name="cp_comercial"
              max="8"
              inputalign="center"
              help="Código Postal Comercial del Proveedor"
              widget="input"
              label="C.P."
              inline-label
              placeholder="CP"
              searchable
              width="64px"
              style="top: 125px; right: 516px"
            />
            <field
              name="poblacion_comercial"
              max="25"
              widget="input"
              inline-label
              placeholder="Población Comercial"
              searchable
              width="273px"
              style="top: 125px; right: 243px"
            />
            <field
              name="provincia_comercial_id"
              widget="m2o"
              dbAdapter="provincia"
              help="Provincia del Proveedor"
              label="Provincia"
              inline-label
              placeholder="Provincia Comercial"
              searchable
              width="260px"
              :fields="[
                'nombre',
                {
                  name: 'pais_id',
                  fields: [
                    'nombre',
                    { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                    'nacionalidad',
                    { name: 'moneda_id', fields: ['nombre'] },
                  ],
                },
              ]"
              style="top: 160px; right: 320px"
            />
            <field
              name="pais_comercial_id"
              widget="m2o"
              dbAdapter="pais"
              placeholder="Pais Comercial"
              help="Pais del Proveedor"
              label="Pais"
              inline-label
              searchable
              width="260px"
              style="top: 190px; right: 320px"
            />

            <field
              name="telefono_comercial"
              max="19"
              widget="input"
              label="Teléfono"
              inline-label
              help="Teléfono Comercial del Proveedor"
              placeholder="Teléfono Comecial"
              searchable
              width="120px"
              style="top: 30px; right: 70px"
            />
            <field
              name="movil_comercial"
              help="Movil Comercial del Proveedor"
              max="19"
              widget="input"
              label="Movil"
              inline-label
              placeholder="Movil Comecial"
              searchable
              width="120px"
              style="top: 65px; right: 70px"
            />
            <field
              name="email_comercial"
              help="E-Mail Comercial del Proveedor"
              max="40"
              widget="input"
              label="E-Mail"
              inline-label
              placeholder="e-mail Comecial"
              searchable
              width="180px"
              style="top: 95px; right: 10px"
              :class="{
                wrong:
                  formData.email_comercial &&
                  !$utils.validation.checkEmail(formData.email_comercial),
              }"
            />
            <field
              name="web_comercial"
              help="Web Comercial del Proveedor"
              max="40"
              widget="input"
              label="Web"
              inline-label
              placeholder="Web Comecial"
              searchable
              width="180px"
              style="top: 125px; right: 10px"
            />
            <field
              name="marcas"
              label="Marcas"
              help="Marcas Comerciales del Proveedor"
              placeholder="Marcas Comeciales"
              widget="m2m"
              dbAdapter="marca"
              width="260px"
              searchable
              style="top: 160px; right: 10px"
              :height="50"
            />
          </div>
          <div class="tab">
            <field
              ref="direcciones"
              name="direcciones"
              widget="handsontable"
              style="top: 5px; left: 5px"
              :columns="[
                { name: 'predet', header: 'Predet.', widget: 'checkbox' },
                { name: 'descripcion', header: 'Descripción' },
                { name: 'responsable', header: 'Responsable' },
                { name: 'direccion', header: 'Dirección' },
                { name: 'direccion1', header: '...' },
                { name: 'cp', header: 'CP' },
                { name: 'poblacion', header: 'Población' },
                {
                  name: 'provincia_id',
                  header: 'Provincia',
                  type: 'm2o',
                  primary: 'codigo',
                  showCode: true,
                },
                {
                  name: 'pais_id',
                  header: 'País',
                  type: 'm2o',
                  primary: 'codigo',
                  showCode: true,
                },
                { name: 'telefono', header: 'Teléfono' },
                { name: 'telefono1', header: 'Teléfono 1' },
                { name: 'movil', header: 'Móvil' },
                { name: 'movil1', header: 'Móvil 1' },
                { name: 'fax', header: 'Fax' },
                { name: 'email', header: 'Email' },
              ]"
              :htSettings="htSettingsDirecciones"
            />
          </div>
          <div class="tab">
            <field
              name="contactos"
              widget="handsontable"
              style="top: 5px; left: 5px"
              :columns="[
                { name: 'nombre', header: 'Nombre' },
                { name: 'cargo', header: 'Cargo' },
                { name: 'telefono', header: 'Teléfono' },
                { name: 'telefono_movil', header: 'Móvil' },
                { name: 'email', header: 'Email' },
              ]"
            />
          </div>
          <div class="tab">
            <fieldset
              style="
                position: relative;
                left: 10px;
                top: 5px;
                width: 633px;
                height: 94px;
              "
            >
              <legend>Compras:</legend>
              <field
                name="descuento_especial"
                widget="input"
                help="% Dto. Especial sobre el total de Compras de este Proveedor"
                label="Dto. Especial"
                placeholder="0.00 %"
                inputalign="center"
                dec="2"
                width="55px"
                style="top: 2px; left: 13px"
              />
              <field
                name="financiacion"
                widget="input"
                help="% Financiación que aparece en la pestaña pie de Compras"
                label="Financiación"
                placeholder="0.00 %"
                inputalign="center"
                dec="2"
                width="55px"
                style="top: 2px; left: 92px"
              />
              <field
                name="descuento_linea_unidad"
                widget="input"
                help="% Dto. Especial que aparece en las lineas de Compras cuando se compra a unidad"
                label="Dto. L. Unidad"
                placeholder="0.00 %"
                inputalign="center"
                dec="2"
                width="55px"
                style="top: 2px; left: 194px"
              />
              <field
                name="descuento_linea_peso"
                widget="input"
                help="% Dto. Especial que aparece en las lineas de Compras cuando se compra a peso"
                label="Dto. L. Peso"
                placeholder="0.00 %"
                inputalign="center"
                dec="2"
                width="55px"
                style="top: 2px; left: 283px"
              />
              <field
                name="merma"
                widget="input"
                help="% Merma asignada a este proveedor en Compras"
                label="%/mm Merma"
                placeholder="0.00 %"
                inputalign="center"
                dec="2"
                width="55px"
                style="top: 2px; left: 359px"
              />
              <field
                name="margen_metal"
                widget="input"
                label="T% Margen Metal"
                placeholder="0.00 %"
                inputalign="center"
                dec="2"
                width="55px"
                style="top: 2px; left: 450px"
              />
              <!--<field
                name="recargo_equivalencia"
                help="Si hay Recargo de Equivalencia"
                widget="checkbox"
                label="R. Equivalencia"
                inline-label
                style="top: 45px; left: 10px"
              />
              <span style="position:absolute;top: 47px; left: 120px;">{{app.config.recargo_equivalencia}}%</span>
              -->
              <!--<field
                name="re"
                widget="input"
                help="Importe del Recargo de Equivalencia"
                dec="2"
                label="RE"
                inline-label
                placeholder
                width="45px"
                style="top:64px; left:136px;"
              />
              <field
                name="tipo_impuesto"
                widget="select"
                :options="{'IVA':'iva', 'IGIC':'igic', 'TAX':'tax','Otro':'otro'}"
                placeholder="Tipo Impuesto"
                width="55px"
                style="top:64px; left:235px;"
                default="iva"
              />
              <field
                name="iva"
                widget="input"
                dec="2"
                label
                inline-label
                placeholder
                width="45px"
                style="top:64px; left:293px;"
              />-->
              <field
                name="impuesto_id"
                widget="m2o"
                label="Impuesto"
                inline-label
                searchable
                width="150px"
                style="top: 45px; left: 170px"
                :fields="['nombre', 'valor']"
                :template-result="
                  (item) =>
                    item.nombre &&
                    item.nombre +
                      ' (' +
                      $utils.misc.formatNumber(item.valor) +
                      '%)'
                "
                :template-selection="
                  (item) =>
                    item.nombre &&
                    item.nombre +
                      ' (' +
                      $utils.misc.formatNumber(item.valor) +
                      '%)'
                "
              />
              <field
                name="metal_linea"
                widget="checkbox"
                label="Metal en Linea"
                inline-label
                style="top: 45px; left: 390px"
              />
              <field
                name="merma_linea"
                widget="checkbox"
                label="Merma por Linea"
                inline-label
                style="top: 45px; left: 496px"
              />
            </fieldset>
            <fieldset
              style="
                position: relative;
                left: 10px;
                top: 10px;
                width: 270px;
                height: 60px;
              "
            >
              <legend>Código de punzón:</legend>
              <field
                name="codigo_punzon"
                widget="input"
                label="Cód. Punzón"
                inline-label
                width="150px"
                required
                inputStyle="text-align:center;"
                style="top: 6px; left: 9px"
              />
            </fieldset>
            <fieldset
              style="
                position: relative;
                left: 290px;
                top: -50px;
                width: 230px;
                height: 59px;
              "
            >
              <legend>Nacionalidad:</legend>
              <field
                name="nacionalidad"
                widget="radio"
                default="nacional"
                :options="{
                  Nacional: 'nacional',
                  'Extracom.': 'extracomunitario',
                  UE: 'union_europea',
                }"
                inline-label
                style="top: 6px; left: 15px"
              />
            </fieldset>
            <field
              name="realiza_reparaciones"
              label="Realiza reparaciones"
              widget="toggle"
              :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50"
              style="top: 120px; right: 15px"
            />
            <field
              name="dias_pago"
              widget="input"
              label="Dias de Pago"
              inline-label
              placeholder="0"
              inputalign="center"
              width="25px"
              style="top: 175px; left: 7px"
            />
            <field
              name="vacaciones_desde_dia"
              widget="input"
              label="Vacaciones Desde"
              inline-label
              placeholder="0"
              inputalign="center"
              width="25px"
              style="top: 175px; left: 120px"
            />
            <field
              name="vacaciones_desde_mes"
              widget="input"
              inline-label
              placeholder="0"
              inputalign="center"
              width="25px"
              style="top: 175px; left: 235px"
            />
            <field
              name="vacaciones_hasta_dia"
              widget="input"
              label="Hasta"
              inline-label
              placeholder="0"
              inputalign="center"
              width="25px"
              style="top: 175px; left: 285px"
            />
            <field
              name="vacaciones_hasta_mes"
              widget="input"
              inline-label
              placeholder="0"
              inputalign="center"
              width="25px"
              style="top: 175px; left: 342px"
            />
            <field
              name="numero_cliente"
              widget="input"
              label="Nº Cliente"
              inline-label
              inputalign="center"
              width="50px"
              style="top: 175px; left: 395px"
            />
            <field
              name="forma_pago_id"
              label="Forma Pago"
              labelProp="descripcion"
              inline-label
              width="200px"
              widget="m2o"
              dbAdapter="forma_pago"
              style="top: 205px; left: 13px"
            />
            <field
              name="divisa_id"
              label="Divisa"
              inline-label
              width="200px"
              widget="m2o"
              dbAdapter="moneda"
              style="top: 205px; left: 290px"
            />
          </div>
          <div class="tab">
            <field
              name="bancos"
              widget="handsontable"
              style="top: 5px; left: 5px"
              :columns="[
                { name: 'predet', header: 'Predet.', widget: 'checkbox' },
                {
                  name: 'cuenta',
                  header: 'Cuenta',
                  renderer: rendererCuenta,
                  allowInvalid: true,
                },
                { name: 'titular', header: 'Titular' },
                {
                  name: 'tipo_cuenta',
                  header: 'Tipo',
                  widget: 'select',
                  options: ['EU', 'INT'],
                },
                { name: 'swift', header: 'SWIFT' },
              ]"
              :htSettings="htSettingsBancos"
            />
          </div>
          <div class="tab"></div>
          <div class="tab">
            <field
              name="notas"
              widget="text"
              placeholder="Escriba aquí las notas..."
              width="645px"
              height="100px"
              style="top: 5px; left: 5px"
            />
            <field
              name="comentarios"
              widget="handsontable"
              :height="111"
              :minRows="4"
              style="top: 115px; left: 5px"
              :columns="[
                { name: 'fecha', header: 'Fecha', type: 'date2' },
                { name: 'descripcion', header: 'Descripción' },
                { name: 'activo', header: 'Activo', type: 'checkbox' },
              ]"
            />
          </div>
          <div class="tab">
            <field
              name="imagen"
              gallery="galeria_proveedores"
              widget="m2o_img"
              style="width: 190px; height: 190px; top: 15px; left: 20px"
              dbAdapter="imagen_proveedor"
            />
            <field
              name="imagen1"
              gallery="galeria_proveedores"
              widget="m2o_img"
              style="width: 85px; height: 85px; top: 10px; left: 328px"
              dbAdapter="imagen_proveedor"
            />
            <field
              name="imagen2"
              gallery="galeria_proveedores"
              widget="m2o_img"
              style="width: 85px; height: 85px; top: 10px; left: 470px"
              dbAdapter="imagen_proveedor"
            />
            <field
              name="imagen3"
              gallery="galeria_proveedores"
              widget="m2o_img"
              style="width: 85px; height: 85px; top: 125px; left: 328px"
              dbAdapter="imagen_proveedor"
            />
            <field
              name="imagen4"
              gallery="galeria_proveedores"
              widget="m2o_img"
              style="width: 85px; height: 85px; top: 125px; left: 470px"
              dbAdapter="imagen_proveedor"
            />
          </div>
          <div class="tab">
            <field
              name="movimientos"
              widget="handsontable"
              style="top: 5px; left: 5px"
              :height="185"
              :minRows="7"
              :readonly="true"
              :columns="[
                {
                  name: 'tipo',
                  header: 'Tipo',
                  type: 'select',
                  options: [
                    {
                      venta: 'Venta',
                      cobro_efectivo: 'Cobro efectivo',
                      cobro_banco: 'Cobro banco',
                      impago: 'Impago',
                      abono: 'Abono',
                    },
                  ],
                },
                { name: 'estado', header: 'Estado' },
                { name: 'fecha', header: 'Fecha', type: 'date2' },
                { name: 'concepto', header: 'Concepto' },
              ]"
            />
            <span style="position: absolute; bottom: 5px; left: 5px">
              <div
                class="n_mov"
                style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  padding: 0px 5px;
                  min-width: 3em;
                  text-align: center;
                  user-select: none;
                "
              ></div>
            </span>
            <span style="position: absolute; bottom: 5px; left: 390px">
              <div
                class="eur_restante"
                style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  font-size: 1.5em;
                  width: 6em;
                  text-align: center;
                "
              ></div>
            </span>
            <span style="position: absolute; bottom: 5px; left: 522px">
              <div
                class="gr_restante"
                style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  font-size: 1.5em;
                  width: 6em;
                  text-align: center;
                "
              ></div>
            </span>
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import CliProvRepMixin from "./../components/CliProvRepMixin.js";
import DirMixin from "./../components/DirMixin.js";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin, CliProvRepMixin, DirMixin],
  data: function () {
    return {
      title: "Proveedores",
      dbAdapter: "proveedor",
      primary: "codigo",
      defaultDataSearch: {
        activo: 1
      },
      defaultData: {
        activo: 1,
        descuento_especial: 0.0,
        financiacion: 0.0,
        descuento_linea_unidad: 0.0,
        descuento_linea_peso: 0.0,
        merma: 0.0,
        margen_metal: 0.0,
        nacionalidad: "nacional",
        /*tipo_impuesto: "iva",*/
      },
      sequence: {
        name: "proveedor",
      },
    };
  },
};
</script>